// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const { useRebrandContext } = require('@context/rebrand-context');

const { Title } = require('@andes/typography');
const ProductDetails = require('@components/product-details');

const BASE_CLASS_NAME = 'credential-products';

const CredentialProducts = ({ isMobile, siteId }) => {
  const { i18n } = useI18n();
  const { assetsPath } = useRebrandContext();

  const getContentBySiteId = () => {
    switch (siteId) {
      case 'MLA':
        return {
          title: i18n.gettext('Disfruta de los beneficios exclusivos que tu revendedor te ofrece'),
          products: [
            {
              id: 'POINT',
              name: i18n.gettext(
                'Cobrá con Point y recibí el dinero de todas las ventas al instante',
              ),
              tag: i18n.gettext('Atención personalizada'),
              image: `${assetsPath}/point-mla-bg.png`,
            },
            {
              id: 'QR',
              name: i18n.gettext(
                'Ofrecé pagos con el QR impreso y facilitá las ventas en tu local',
              ),
              tag: i18n.gettext('Tasa 0 durante 3 meses'),
              image: isMobile
                ? `${assetsPath}/qr-mla-mobile.png`
                : `${assetsPath}/qr-mla-desktop.png`,
            },
          ],
        };

      case 'MLB':
        return {
          title: i18n.gettext('Aprovecha los beneficios exclusivos ofrecidos por tu revendedor'),
          products: [
            {
              id: 'POINT',
              name: i18n.gettext(
                'Vende con Point y elige la tarifa y cuándo quieres recibir el dinero de tus ventas',
              ),
              tag: i18n.gettext('Sin mensualidades'),
              image: isMobile
                ? `${assetsPath}/point-mlb-mobile-2.webp`
                : `${assetsPath}/point-mlb-2.webp`,
            },
          ],
        };

      case 'MLM':
        return {
          title: i18n.gettext(
            'Disfruta de los beneficios exclusivos que tu representante te ofrece',
          ),
          products: [
            {
              id: 'POINT',
              name: i18n.gettext(
                'Cobra con Point y recibe el dinero de todas las ventas al instante',
              ),
              tag: i18n.gettext('Tasa 0% por 30 días'),
              image: `${assetsPath}/point-mlm.png`,
            },
          ],
        };

      default:
        return {};
    }
  };

  const { title, products } = getContentBySiteId();

  return (
    <section className={BASE_CLASS_NAME}>
      <div className="top-border" />
      {title && <Title component="h2">{title}</Title>}
      {products?.map((product) => (
        <ProductDetails key={product} isMobile={isMobile} product={product} siteId={siteId} />
      ))}
    </section>
  );
};

CredentialProducts.propTypes = {
  isMobile: PropTypes.bool,
  siteId: PropTypes.string,
};

module.exports = CredentialProducts;
